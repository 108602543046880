/* eslint-disable react/no-unescaped-entities */
import React from 'react';

const PrivacyComponent: React.FC = () => {
  return (
    <div className="w-full p-4 dark:bg-dark_bg_secondary bg-white">
      <div className="mx-auto sm:flex max-w-7xl w-full flex-col justify-between gap-3 px-2 py-4 2xl:px-0 lg:px-8">
        <h1 className="text-3xl text-black font-semibold mb-2">
          Privacy Policy
        </h1>
        <h2 className="text-xl font-medium">
          Effective Date: September 15, 2024
        </h2>
        <p className="my-3 sm:text-lg">
          Onelife Rentals LLC ("we", "our", or "us") is committed to protecting
          your privacy. This Privacy Policy describes how we collect, use, and
          share personal information when you use our mobile application (the
          "App") within the United States. It also outlines your rights
          regarding your personal information under U.S. law, including the{' '}
          <span className="font-semibold">
            California Consumer Privacy Act (CCPA)
          </span>
          .
        </p>
        <div className="sm:text-lg mx-7">
          <ol className="list-decimal flex flex-col gap-3 marker:font-semibold">
            <li>
              {/* <span className="text-xl font-semibold">Information We Collect</span> */}
              <div className="mb-3">
                <h1 className="text-xl font-semibold">
                  Information We Collect
                </h1>
                <p className="mt-3">
                  We may collect the following categories of personal
                  information when you use our App:
                </p>
                <div className="mt-3">
                  <ul className="list-disc sm:flex flex-col gap-3 flex xsm:ml-5 lg:ml-10 mt-2">
                    <li>
                      <p className="font-semibold">
                        Email Address:{' '}
                        <span className="font-normal">
                          Used for account creation, authentication, and
                          communication.
                        </span>
                      </p>
                    </li>
                  </ul>
                  <ul className="list-disc sm:flex flex-col gap-3 flex xsm:ml-5 lg:ml-10 mt-2">
                    <li>
                      <p className="font-semibold">
                        Phone Number:{' '}
                        <span className="font-normal">
                          Used for account verification, security, and customer
                          support.
                        </span>
                      </p>
                    </li>
                  </ul>
                  <ul className="list-disc sm:flex flex-col gap-3 flex xsm:ml-5 lg:ml-10 mt-2">
                    <li>
                      <p className="font-semibold">
                        Address/Location:{' '}
                        <span className="font-normal">
                          Used for location-based services, personalization, and
                          enhancing app functionality.
                        </span>
                      </p>
                    </li>
                  </ul>
                  <ul className="list-disc sm:flex flex-col gap-3 flex xsm:ml-5 lg:ml-10 mt-2">
                    <li>
                      <p className="font-semibold">
                        User's Name:{' '}
                        <span className="font-normal">
                          Used for account management, personalization, and user
                          identification.
                        </span>
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
            </li>
            <li>
              <div className="mb-3">
                <h1 className="text-xl font-semibold">
                  How We Use Your Information
                </h1>
                <p className="mt-3">
                  We may use the information we collect from you for the
                  following purposes:
                </p>
                <div className="mt-3">
                  <ul className="list-disc sm:flex flex-col gap-3 flex xsm:ml-5 lg:ml-10 mt-2">
                    <li>
                      <p className="font-semibold">
                        To Provide Services:{' '}
                        <span className="font-normal">
                          To enable core app functionalities, provide
                          personalized features, and improve your overall
                          experience.
                        </span>
                      </p>
                    </li>
                  </ul>
                  <ul className="list-disc sm:flex flex-col gap-3 flex xsm:ml-5 lg:ml-10 mt-2">
                    <li>
                      <p className="font-semibold">
                        To Communicate with You:{' '}
                        <span className="font-normal">
                          To send account updates, service notifications, and
                          marketing communications (which you can opt out of at
                          any time).
                        </span>
                      </p>
                    </li>
                  </ul>
                  <ul className="list-disc sm:flex flex-col gap-3 flex xsm:ml-5 lg:ml-10 mt-2">
                    <li>
                      <p className="font-semibold">
                        Location-Based Services:{' '}
                        <span className="font-normal">
                          To offer relevant content, services, or functionality
                          based on your geographical location.
                        </span>
                      </p>
                    </li>
                  </ul>
                  <ul className="list-disc sm:flex flex-col gap-3 flex xsm:ml-5 lg:ml-10 mt-2">
                    <li>
                      <p className="font-semibold">
                        Account and Security Management:{' '}
                        <span className="font-normal">
                          To verify your identity, protect your account from
                          unauthorized access, and ensure the security of our
                          systems.
                        </span>
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
            </li>
            <li>
              <div className="mb-3">
                <h1 className="text-xl font-semibold">
                  Sharing of Personal Information
                </h1>
                <p className="mt-3">
                  We do not sell your personal information. However, we may
                  share your information with third parties under the following
                  circumstances:
                </p>
                <div className="mt-3">
                  <ul className="list-disc sm:flex flex-col gap-3 flex xsm:ml-5 lg:ml-10 mt-2">
                    <li>
                      <p className="font-semibold">
                        Service Providers:{' '}
                        <span className="font-normal">
                          We share your information with trusted third-party
                          service providers who assist us in operating the App,
                          providing services, or performing analytics. These
                          providers are bound by confidentiality agreements.
                        </span>
                      </p>
                    </li>
                  </ul>
                  <ul className="list-disc sm:flex flex-col gap-3 flex xsm:ml-5 lg:ml-10 mt-2">
                    <li>
                      <p className="font-semibold">
                        Legal Compliance:{' '}
                        <span className="font-normal">
                          We may disclose your information if required by law,
                          such as in response to a subpoena, government
                          investigation, or to protect our rights and the rights
                          of others.
                        </span>
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
            </li>
            <li>
              <div className="mb-3">
                <h1 className="text-xl font-semibold">
                  California Privacy Rights (CCPA)
                </h1>
                <p className="mt-3">
                  If you are a California resident, you have specific rights
                  under the{' '}
                  <span className="font-semibold">
                    California Consumer Privacy Act (CCPA)
                  </span>
                  :
                </p>
                <div className="mt-3">
                  <ul className="list-disc sm:flex flex-col gap-3 flex xsm:ml-5 lg:ml-10 mt-2">
                    <li>
                      <p className="font-semibold">
                        Right to Know:{' '}
                        <span className="font-normal">
                          You can request a report detailing the categories and
                          specific pieces of personal information we have
                          collected about you in the past 12 months.
                        </span>
                      </p>
                    </li>
                  </ul>
                  <ul className="list-disc sm:flex flex-col gap-3 flex xsm:ml-5 lg:ml-10 mt-2">
                    <li>
                      <p className="font-semibold">
                        Right to Delete:{' '}
                        <span className="font-normal">
                          You can request that we delete the personal
                          information we have collected, subject to certain
                          exceptions.
                        </span>
                      </p>
                    </li>
                  </ul>
                  <ul className="list-disc sm:flex flex-col gap-3 flex xsm:ml-5 lg:ml-10 mt-2">
                    <li>
                      <p className="font-semibold">
                        Right to Opt-Out of Sale:{' '}
                        <span className="font-normal">
                          While we do not sell personal information, California
                          residents have the right to opt-out of any future sale
                          of personal information.
                        </span>
                      </p>
                    </li>
                  </ul>
                  <ul className="list-disc sm:flex flex-col gap-3 flex xsm:ml-5 lg:ml-10 mt-2">
                    <li>
                      <p className="font-semibold">
                        Non-Discrimination:{' '}
                        <span className="font-normal">
                          You have the right not to receive discriminatory
                          treatment for exercising any of your CCPA rights.
                        </span>
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
            </li>
            <li>
              <div className="mb-3">
                <h1 className="text-xl font-semibold">Data Security</h1>
                <p className="mt-3">
                  We implement appropriate technical and organizational measures
                  to protect your personal information. However, no method of
                  electronic transmission or storage is 100% secure, and we
                  cannot guarantee absolute security.
                </p>
              </div>
            </li>
            <li>
              <div className="mb-3">
                <h1 className="text-xl font-semibold">Data Retention</h1>
                <p className="mt-3">
                  We retain your personal information only as long as necessary
                  to fulfill the purposes for which it was collected or as
                  required by law. Once the information is no longer needed, we
                  will securely delete or anonymize it.
                </p>
              </div>
            </li>
            <li>
              <div className="mb-3">
                <h1 className="text-xl font-semibold">
                  Children's Privacy (COPPA)
                </h1>
                <p className="mt-3">
                  Our App is not intended for use by children under the age of
                  13. We do not knowingly collect or solicit personal
                  information from children under 13. If we learn that we have
                  collected personal information from a child under 13, we will
                  delete it in compliance with the{' '}
                  <span className="font-semibold">
                    Children's Online Privacy Protection Act (COPPA)
                  </span>
                  . If you believe we may have collected information from a
                  child under 13, please contact us at [Contact Information].
                </p>
              </div>
            </li>
            <li>
              <div className="mb-3">
                <h1 className="text-xl font-semibold">Your Rights</h1>
                <p className="mt-3">
                  You have certain rights regarding your personal information:
                </p>
                <div className="mt-3">
                  <ul className="list-disc sm:flex flex-col gap-3 flex xsm:ml-5 lg:ml-10 mt-2">
                    <li>
                      <p className="font-semibold">
                        Access:{' '}
                        <span className="font-normal">
                          You can request a copy of the personal information we
                          hold about you.
                        </span>
                      </p>
                    </li>
                  </ul>
                  <ul className="list-disc sm:flex flex-col gap-3 flex xsm:ml-5 lg:ml-10 mt-2">
                    <li>
                      <p className="font-semibold">
                        Correction:{' '}
                        <span className="font-normal">
                          You can request that we correct inaccurate or
                          incomplete personal data.
                        </span>
                      </p>
                    </li>
                  </ul>
                  <ul className="list-disc sm:flex flex-col gap-3 flex xsm:ml-5 lg:ml-10 mt-2">
                    <li>
                      <p className="font-semibold">
                        Deletion:{' '}
                        <span className="font-normal">
                          You can request the deletion of your personal data,
                          subject to any legal obligations to retain it.
                        </span>
                      </p>
                    </li>
                  </ul>
                  <ul className="list-disc sm:flex flex-col gap-3 flex xsm:ml-5 lg:ml-10 mt-2">
                    <li>
                      <p className="font-semibold">
                        Marketing Opt-Out:{' '}
                        <span className="font-normal">
                          You can opt out of receiving marketing communications
                          by following the unsubscribe instructions in our
                          communications or contacting us directly.
                        </span>
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
            </li>
            <li>
              <div className="mb-3">
                <h1 className="text-xl font-semibold">
                  Changes to This Privacy Policy
                </h1>
                <p className="mt-3">
                  We reserve the right to update this Privacy Policy from time
                  to time. Any changes will be posted here, and we will notify
                  you of any significant changes by email or through the App.
                  The "Effective Date" at the top of this policy indicates when
                  the most recent changes were made.
                </p>
              </div>
            </li>
            <li>
              <div className="mb-3">
                <h1 className="text-xl font-semibold">Contact Us</h1>
                <p className="mt-3">
                  If you have any questions or concerns regarding this Privacy
                  Policy or our data practices, please contact us at:
                </p>
                <div className="mt-3 flex flex-col gap-3">
                  <p className="font-semibold">
                    Email:{' '}
                    <span className="font-normal">
                      rentalsonelife@gmail.com
                    </span>
                  </p>
                  <p className="font-semibold">
                    Address:{' '}
                    <span className="font-normal">
                      5200 Highland Drive #203 Holladay Utah 84117
                    </span>
                  </p>
                </div>
              </div>
            </li>
          </ol>
        </div>
      </div>
    </div>
  );
};

export default PrivacyComponent;
