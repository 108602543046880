import MoneyIcon from './../Assets/money.png';
import PagesIcon from './../Assets/pages.png';
import BoatIcon from './../Assets/boat.png';
import atvImg from './../Assets/Images/atv-min.png';
import img1 from './../Assets/TopRecommendedImg/img1.png';
import img2 from './../Assets/TopRecommendedImg/img2.png';
import img3 from './../Assets/TopRecommendedImg/img3.png';
import img4 from './../Assets/TopRecommendedImg/img4.png';
import img5 from './../Assets/TopRecommendedImg/img5.png';
import img6 from './../Assets/TopRecommendedImg/img6.png';
import img7 from './../Assets/TopRecommendedImg/img7.png';
import img8 from './../Assets/TopRecommendedImg/img8.png';
import img9 from './../Assets/TopRecommendedImg/img9.png';
import img10 from './../Assets/TopRecommendedImg/img10.png';
import testimonialImg1 from './../Assets/../Assets/Images/Rectangle 23809 (7).png';
import testimonialImg2 from './../Assets/Images/Rectangle 23809 (9).png';
import testimonialImg3 from './../Assets/Images/Rectangle 23809 (10).png';
import testimonialImg4 from './../Assets/Images/Rectangle 23809 (8).png';
import testimonialImg5 from './../Assets/Images/Rectangle 23809 (11).png';
import testimonialImg6 from './../Assets/Images/Rectangle 23809 (12).png';
import testimonialImg7 from './../Assets/Images/Rectangle 23809 (15).png';
import testimonialImg8 from './../Assets/Images/Rectangle 23809 (14).png';
import testimonialImg9 from './../Assets/Images/Rectangle 23809 (13).png';
// import listingImg1 from './../Assets/Frame 6356469 (3).png'
// import listingImg2 from './../Assets/Frame 6356469 (4).png'
// import listingImg3 from './../Assets/Frame 6356469 (5).png'
// import listingImg4 from './../Assets/Rectangle 23809 (16).png'

export const ApplicationString = {
  comingSoonPage: {
    comingSoon: {
      title: 'Coming Soon!',
      text: 'Your privacy & terms and conditions are important to us. Our privacy policy and terms and conditions will be available soon. Please check back later...',
    },
  },
  header: {
    button: {
      rentVehicle: 'Rent Vehicle',
      listMyVehicle: 'List My Vehicle',
      signup: 'Sign Up',
      login: 'Log In',
      Owner: 'Become an Owner',
    },
  },
  exploreSection: {
    vehicleCategory: {
      landVehicle: 'Land Vehicle',
      waterCraft: 'Water Craft',
    },
  },
  footer: {
    text: {
      connectWithUs: 'Connect with us',
      downloadOnlifeApp: 'Download onelife app now!',
      privacy: 'Privacy Policy',
      terms: 'Terms & Conditions',
    },
  },
  accordion: {
    title: 'Frequently Asked Questions',
    description:
      'Explore our well-kept boats, from yachts to pontoons, for an enhanced maritime adventure.',
    accordionData: [
      {
        id: 1,
        question: 'How much does it cost to rent?',
        answer: 'Click “book now” to show the most up-to-date pricing!',
      },
      {
        id: 2,
        question: 'How do I book?',
        answer: 'You can book directly on our website 24/7!',
      },
      {
        id: 3,
        question: 'Do I need to book in advance?',
        answer:
          'Yes! We tend to book out a few days in advance, so reservation slots are available. You can see our availability in our booking system.',
      },
      {
        id: 4,
        question: 'What is short notice booking or instant booking?',
        answer:
          'The bookings that are to begin within less than 48 hours of the actual booking start time are considered Instant Bookings. In this type of booking, the renter will have to wait for the vehicle owner’s approval and only after that, the booking will be confirmed. If the booking is not accepted by the owner within two hours of submitting a booking request, it will automatically be canceled.',
      },
      {
        id: 5,
        question: 'How is the security deposit calculated?',
        answer:
          'The renter will collect the deposit 48 hours before the adventure begins. The deposit amount will be either $250 or 10% of the rental rate.',
      },
      {
        id: 6,
        question: 'What is your cancellation policy?',
        answer: `
        <div>
        <p><strong>Regular Bookings:</strong></p>
        <ul class="list-disc pl-5">
          <li >If a renter cancels the booking 14 days before the start of an adventure, they receive a full refund.</li>
          <li >If the renter cancels between 14 days to 48 hours of the start of an adventure, they receive a 50% refund.</li>
          <li >If the renter cancels in less than 48 hours before the start of an adventure, they receive no refund.</li>
        </ul>
        <p class="mt-2"><strong>Instant Bookings:</strong></p>
        <ul class="list-disc pl-5">
          <li >If the vehicle is booked and the adventure is to begin in less than 48 hours from the time of booking, the owner is notified of the booking. The owner will have 2 hours to accept the booking.</li>
          <li >If the owner accepts the short-notice booking, the renter will be notified of the confirmed booking.</li>
          <li >If the owner fails to accept/reject the booking in 2 hours, then the booking will be canceled automatically.</li>
        </ul>
      </div>
`,
      },
    ],
  },

  userlistingCard: {
    backgroundImage:
      'https://images.unsplash.com/photo-1628104079324-7ac5295c7619?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
    title: 'Do you want to list your own Vehicle?',
    description:
      'Onelife is a community of outdoor enthusiasts who use our sharing platform to offset the cost of ownership. Join us today!',
    buttonText: 'Create My Listing Now',
  },
  OwnerlistingCard: {
    backgroundImage:
      'https://images.unsplash.com/photo-1628104079324-7ac5295c7619?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
    title: ' Do you want to list your own Vehicle?',
    description:
      'Onelife is a community of outdoor enthusiasts who use our sharing platform to offset the cost of ownership. Join us today!',
    buttonText: 'Create My Listing Now',
  },
  newsletterForm: {
    backgroundImage:
      'https://images.unsplash.com/photo-1565733293285-77aa342b22dd?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
    title: 'Subscribe to Newsletter',
    description: 'Stay in the loop',
    buttonText: 'Subscribe',
    subscribedText: 'Subscribed',
    formDiscription: 'Please fill the subscribe form',
    inputLabel: {
      email: 'Email :',
      name: 'Name :',
    },
    inputTitle: {
      email: 'Please enter a valid email address',
      name: 'Please enter your name',
    },
    placeHolder: {
      email: 'Enter your email',
      name: 'Enter your name',
    },
    subscribedCard: 'Subscribed!',
    subscribedCardText: {
      p1: 'Congratulations! You have officially joined our email',
      p2: 'newsletter subscription list.',
    },
    cardButtonText: 'Done',
    invalidNameFieldText: 'Please enter your name',
    successMessage: 'Thank you for subscribing to onelife newsletter!!',
    buttonSubscribing: 'Subscribing...',
  },
  mobileSection: {
    title: 'Download Our Mobile App',
    description: 'Use OneLife’s mobile app to get an amazing experience...',
  },
  featureCards: {
    title: 'How It Works?',
    description:
      'Embark on exciting adventures by exploring our meticulously maintained vehicles, for an unforgettable experience!',
    featureData: [
      {
        icon: BoatIcon,
        title: 'List Vehicle',
        description:
          'Effortlessly explore a wide range of vehicles on land and water with a swift and user-friendly search through our comprehensive listings.',
      },
      {
        icon: PagesIcon,
        title: 'Instant Booking',
        description:
          'Secure instant bliss on the waves with our swift and hassle-free Vehicle booking service – your land & Aquatic adventure is just a click away.',
      },
      {
        icon: MoneyIcon,
        title: 'List VehicleEarn Money',
        description:
          'Indulge in the best of both worlds as you savor the thrilling experiences of land and watercraft adventures.',
      },
    ],
  },

  userHeroSection: {
    image: atvImg,
    title: 'Earn Money with onelife',
    subtitle: 'List Your Vehicle Now',
    description:
      'Discover easy rentals for both land and water trips, ensuring a memorable journey with us',
  },
  priceCard: {
    title: 'Earn on OneLife as an Owner',
    description:
      'Discover stunning landscapes and vibrant culture. Immerse yourself in rich history and unforgettable experiences.',
    buttonText: 'List My Vehicle',
    cards: [
      {
        color: 'blue',
        amount: '$~299+',
        description: 'Avg. Earning on 1 Vehicle Per Day',
      },
      {
        color: 'slate',
        amount: '$~1000+',
        description: 'Avg. Earning on 5 Vehicle Per Day',
      },
      {
        color: 'green',
        amount: '$~5000+',
        description: 'Avg. Earning on 10 Vehicle Per Day',
      },
    ],
  },
  ownerHeroSection: {
    title: 'Create MemOOries on Land and Water',
    subtitle: 'Rent Your Ride Now',
    description:
      'Discover easy rentals for both land and water trips, ensuring a memorable journey with us',
  },
  popularCategories: {
    heading: {
      title: 'Popular Categories',
      description:
        'Discover stunning landscapes and vibrant culture. Immerse yourself in rich history and unforgettable experiences.',
    },
    categories: [
      {
        id: '1',
        title: 'ATV',
        imageSrc:
          'https://images.unsplash.com/photo-1628104079324-7ac5295c7619?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
      },
      {
        id: '2',
        title: 'UTV',
        imageSrc:
          'https://images.unsplash.com/photo-1705571136100-19f1c0f1e69d?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
      },
      {
        id: '3',
        title: 'Yacht',
        imageSrc:
          'https://images.unsplash.com/photo-1567899378494-47b22a2ae96a?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
      },
      {
        id: '4',
        title: 'HouseBoat',
        imageSrc:
          'https://images.unsplash.com/photo-1634141693341-9d51836aa188?q=80&w=2071&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
      },
    ],
  },
  testimonials: {
    heading: {
      title: 'What People Says About Onelife',
      description:
        'Explore our well-kept boats, from yachts to pontoons, for an enhanced maritime adventure.',
    },
    testimonials: [
      {
        quote:
          'Had an unforgettable day on one of their boats. Clean, well-maintained, and the staff was incredibly helpful.',
        author: 'Theresa Webb',
        position: 'Chief Marketing Officer',
        image: testimonialImg1,
      },

      {
        quote:
          'Booking a boat with this fleet was the highlight of our vacation. The options available were impressive, catering to every preference and budget. From sleek yachts to spacious pontoons, we found the perfect vessel for our family outing. The entire process, from reservation to return, was seamless and stress-free. Will definitely be recommending this service to friends and family.',
        author: 'Darrell Steward',
        position: 'Business Analyst',
        image: testimonialImg4,
      },

      {
        quote:
          'Booking a boat with this fleet was the highlight of our vacation. ',
        author: 'Courtney Henry',
        position: 'Chief Marketing Officer',
        image: testimonialImg7,
      },
      {
        quote: `Our maritime adventure exceeded all expectations! The fleet offered a diverse range of boats, ensuring an enjoyable experience for our group. The customer service was exceptional, with staff going above and beyond to accommodate our needs. Highly satisfied and eagerly looking forward to our next excursion.`,
        author: 'Ronald Richards',
        position: 'Trainee Engineer',
        image: testimonialImg2,
      },

      {
        quote: `From start to finish, everything was seamless. The boats were immaculate, and the booking process was hassle-free. Can't wait to return for another excursion.`,
        author: 'Kristin Watson',
        position: 'Technical Specialist',
        image: testimonialImg5,
      },
      {
        quote: `Absolutely loved our experience with the fleet! The boats were in pristine condition, and we had a blast cruising the waters. The attention to detail was evident in every aspect, from the cleanliness of the vessels to the professionalism of the staff.`,
        author: 'Ralph Edwards',
        position: 'Chief Marketing Officer',
        image: testimonialImg8,
      },
      {
        quote: `Highly impressed with the quality of boats and service provided by this fleet. Whether you're a seasoned sailor or a first-time boater, they have something for everyone. Can't wait to return for another adventure.`,
        author: 'Cameron Williamson',
        position: 'Program Manager',
        image: testimonialImg3,
      },
      {
        quote:
          'Booking a boat with this fleet was the highlight of our vacation. The options available were impressive, catering to every preference and budget. From sleek yachts to spacious pontoons, we found the perfect vessel for our family outing. The entire process, from reservation to return, was seamless and stress-free. Will definitely be recommending this service to friends and family.',
        author: 'Kathryn Murphy',
        position: 'System Analyst',
        image: testimonialImg6,
      },

      {
        quote:
          'Absolutely loved our experience with the fleet! The boats were in pristine condition, and we had a blast cruising the waters. Highly recommend.',
        author: 'Devon Lane',
        position: 'CEO',
        image: testimonialImg9,
      },
    ],
  },
  destinations: {
    heading: {
      title: 'Top Recommended Destinations',
      description: 'Create the moments that become memories.',
    },
    unitData: {
      maxRating: '5',
      Vehicles: 'Vehicles',
    },
    destinationData: [
      {
        imageUrl: img10,
        title: 'Fort Worth Texas',
        vehicleCount: 30,
        rating: 4.9,
        linkText: 'Explore Now',
      },
      {
        imageUrl: img4,
        vehicleCount: 25,
        rating: 4.8,
        linkText: 'Explore Now',
      },
      {
        imageUrl: img5,
        title: 'Fort Wayne Indiana',
        vehicleCount: 20,
        rating: 4.7,
        linkText: 'Explore Now',
      },
      {
        imageUrl: img3,
        vehicleCount: 15,
        rating: 4.6,
        linkText: 'Explore Now',
      },
      {
        imageUrl: img1,
        title: 'Fresno California',
        vehicleCount: 15,
        rating: 4.6,
        linkText: 'Explore Now',
      },
      {
        imageUrl: img6,
        title: 'Fremont California',
        vehicleCount: 25,
        rating: 4.8,
        linkText: 'Explore Now',
      },
      {
        imageUrl: img7,
        vehicleCount: 20,
        rating: 4.7,
        linkText: 'Explore Now',
      },
      {
        imageUrl: img8,
        title: 'Fayetteville North Carolina',
        vehicleCount: 15,
        rating: 4.6,
        linkText: 'Explore Now',
      },
      {
        imageUrl: img2,
        title: 'Fontana California',
        vehicleCount: 30,
        rating: 4.9,
        linkText: 'Explore Now',
      },
      {
        imageUrl: img9,
        vehicleCount: 25,
        rating: 4.8,
        linkText: 'Explore Now',
      },
      {
        imageUrl: img1,
        title: 'Florida State',
        vehicleCount: 20,
        rating: 4.7,
        linkText: 'Explore Now',
      },
      {
        imageUrl: img3,
        vehicleCount: 15,
        rating: 4.6,
        linkText: 'Explore Now',
      },
    ],
  },
  ownerListing: {
    heading: {
      title: 'Popular Listing',
      description:
        'Discover stunning landscapes and vibrant culture. Immerse yourself in rich history and unforgettable experiences.',
    },
    unitData: {
      cabins: 'cabins',
      personCapacity: 'person capacity',
      length: 'ft length',
      hour: 'per hour',
      currency: '$',
    },
    listingData: [
      {
        id: 1,
        name: 'HydroSeedo Elite',
        location: 'New Jersey',
        cabins: 6,
        capacity: 12,
        length: 24,
        description:
          'With modern amenities, spacious interiors, and panoramic views, this boat offers an unforgettable aquatic experience.',
        price: 249.99,
        imageUrl:
          'https://images.unsplash.com/photo-1623251349546-9fc522667b71?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
      },
      {
        id: 1,
        name: 'HydroSeedo Elite',
        location: 'New Jersey',
        cabins: 6,
        capacity: 12,
        length: 24,
        description:
          'With modern amenities, spacious interiors, and panoramic views, this boat offers an unforgettable aquatic experience.',
        price: 249.99,
        imageUrl:
          'https://plus.unsplash.com/premium_photo-1683742899898-e39333fe4b28?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTI1fHxiaWtlc3xlbnwwfHwwfHx8MA%3D%3D',
      },
      {
        id: 1,
        name: 'HydroSeedo Elite',
        location: 'New Jersey',
        cabins: 6,
        capacity: 12,
        length: 24,
        description:
          'With modern amenities, spacious interiors, and panoramic views, this boat offers an unforgettable aquatic experience.',
        price: 249.99,
        imageUrl:
          'https://plus.unsplash.com/premium_photo-1677354135616-5dd7a4a4a438?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MXx8Ym9hdHxlbnwwfHwwfHx8MA%3D%3D',
      },
      {
        id: 1,
        name: 'HydroSeedo Elite',
        location: 'New Jersey',
        cabins: 6,
        capacity: 12,
        length: 24,
        description:
          'With modern amenities, spacious interiors, and panoramic views, this boat offers an unforgettable aquatic experience.',
        price: 249.99,
        imageUrl:
          'https://images.unsplash.com/photo-1511316695145-4992006ffddb?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MzV8fGNydXNlJTIwc2hpcHxlbnwwfHwwfHx8MA%3D%3D',
      },
      // Add more listings as needed
    ],
  },
};
